/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // Toggle Menu
                var header = $('.o-header');
                header.on('click', '.o-header--toggle', function () {
                    header.toggleClass('s-menu-open');
                });

                header.find('.sub-menu').siblings('a').after('<span class="menu-slide"></span>');
                header.find('.menu-slide').on('click',function(e){
                    $(this).toggleClass('open');
                    e.preventDefault();
                    e.stopPropagation();
                    $(this).siblings('.sub-menu').stop(true, true).slideToggle(200);
                });


                // Search Bar
                var searchForm = $('.o-header--search');
                var searchFormToggle = $('.o-header--search--toggle');
                searchFormToggle.on('click',function(){
                    searchForm.toggleClass('search--displayed');
                    if(searchForm.hasClass('search-displayed')){
                        searchForm.find('.search-field').focus();
                    }
                });
            },
            finalize: function () {
                 /// Featured Recipe 
                var featured_recipe = $('.j-featured-recipe');
                if( featured_recipe.length > 0 ){
                    featured_recipe.on('click','.j-featured-recipe--next', function(){
                        
                        if(!featured_recipe.hasClass('ajax_active')){
                            featured_recipe.addClass('ajax_active');
                            jQuery.ajax({
                                data: {
                                    action: 'sc_ajax_featuredRecipe',
                                    not_in: featured_recipe.find('j-featured-recipe--single').data('id'),
                                    read_more: featured_recipe.find('.sc-feature-recipe--recipe--view-recipe').text(),
                                },
                                dataType: 'html',
                                url: ajaxURL,
                                success: function (rawhtml) {
                                    // Replace HTML with filtered results
                                    featured_recipe.removeClass('ajax_active');
                                    featured_recipe.find('.j-featured-recipe--container').html(rawhtml);
                                }
                            });
                        }
                    });
                }

                var carousel_banner = $('.j-carousel-banner');
                if( carousel_banner.length > 0 ){
                    // JavaScript to be fired on the home page
                    carousel_banner.find('.j-carousel-banner--typed').text('');
                    var typed = new Typed('.j-carousel-banner--typed', {
                        stringsElement: '.j-carousel-banner--typed-strings',
                        typeSpeed: 100,
                        backSpeed: 25,
                        startDelay: 500,
                        backDelay: 4000,
                        loop: true,
                        smartBackspace: false
                    });
                }


                /// IMAGE CAROUSEL
                var image_carousel = $('.j-carousel--image');
                if( image_carousel.length > 0 ){
                    image_carousel.slick({
                        centerMode: true,
                        centerPadding: '175px',
                    });
                }

                /// logo CAROUSEL
                var icon_carousel = $('.j-carousel__icons');
                if( icon_carousel.length > 0 ){
                    icon_carousel.slick({
                        arrows: true,
                        dots: false,
                        slidesToShow: 6,
                        slidesToScroll: 1,
                        responsive: [
                            {
                              breakpoint: 1000,
                              settings: {
                                slidesToShow: 4,
                                slidesToScroll: 1,
                              }
                            },
                            {
                              breakpoint: 800,
                              settings: {
                                slidesToShow: 3,
                                slidesToScroll: 1,
                              }
                            },
                        ]
                    });
                }


                // /// testimonial CAROUSEL
                var testimonial_carousel = $('.j-carousel__testimonials');
                if( testimonial_carousel.length > 0 ){
                    var testimonial_carousel_slick = testimonial_carousel.slick({
                        arrows: true,
                        dots: false,
                    });
                    testimonial_carousel.on('click','.sc-testimonials--dots-single', function(){
                        testimonial_carousel_slick.slick('slickGoTo', $(this).data('slick'));
                    });
                }

                /// Related CAROUSEL
                var related_carousel = $('.sc-related--row');
                if( related_carousel.length > 0 ){
                    related_carousel.slick({
                        arrows: true,
                        dots: false,
                        slidesToShow: 3,
                        responsive: [
                            {
                              breakpoint: 900,
                              settings: {
                                slidesToShow: 1,
                              }
                            },
                        ]
                    });
                }

                


                $('input').each(function() {
                
                    $(this).on('focus', function() {
                    $(this).parent().parent('.gfield').addClass('active');
                    });
                
                    $(this).on('blur', function() {
                    if ($(this).val().length == 0) {
                        $(this).parent().parent('.gfield').removeClass('active');
                    }
                    });
                    
                    if ($(this).val() != '') $(this).parent('.css').addClass('active');
                
                });
                $('textarea').each(function() {
                
                    $(this).on('focus', function() {
                    $(this).parent().parent('.gfield').addClass('active');
                    });
                
                    $(this).on('blur', function() {
                    if ($(this).val().length == 0) {
                        $(this).parent().parent('.gfield').removeClass('active');
                    }
                    });
                    
                    if ($(this).val() != '') $(this).parent('.css').addClass('active');
                
                });
                function updateBlogPosts(permalink){
                    loadmore = $('.j-load-more');
                    $('html,body').animate({
                        scrollTop: $('.j-filter--scroll-target').offset().top - 100
                     }, 200 );
                    window._fetch.callbackContent(permalink, function(newPage) {
                        var $newPage = $(newPage);
                        loadmore.find('.j-load-more--posts').html($newPage.find('.j-load-more--posts').html());
                        loadmore.find('.o-post-index--filters').html($newPage.find('.o-post-index--filters').html());
                        loadmore.find('.j-filter--count').html($newPage.find('.j-filter--count').html());
                        loadmore.find('.j-load-more--pagination').html($newPage.find('.j-load-more--pagination').html());
                        window._fetch.setURL(permalink);
                    });
                }

                $('.j-load-more').on('click','.o-posts--pagination a',function(e){
                    e.preventDefault();
                    updateBlogPosts($(this).attr('href'));
                   
                });
                $('.j-load-more').on('change', '.j-post-filter', function(){
                    var permalink = $(this).data('permalink');
                    var extension = "?";

                    $('.j-post-filter').each(function(){

                        var _this = $(this);
                        var value = _this.val();
                        var array = value.split('__');
                        if(array[0].length > 1 ){
                            permalink += extension + array[0] + "=" + array[1];
                            extension = "&";
                        }
                    });
                    updateBlogPosts(permalink);

                });

                $('.j-recipe--share').on('click',function(){
                    lity($('.j-recipe--share--popup'));
                });

            }
        },
        // Home page
        'page_template_homepage': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
            }
        },

        // Home page
        'blog': {
            init: function () {
                // JavaScript to be fired on the home page

            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS


            }
        },


    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

